.c_pAGa_container {
  padding: 20px;
}

.c_pAGa_button:not(:last-child) {
  margin-right: 4px;
}

.c_pAGa_table {
  table-layout: fixed;
  width: 800px;
  border-spacing: 0;
  border: 1px solid #333;
  margin: 50px auto;
}

.c_pAGa_table th, .c_pAGa_table td {
  word-wrap: break-word;
  border: 1px solid #333;
  padding: 10px;
}

.c_pAGa_table th {
  font-weight: bold;
}

body {
  color: #dadae0;
  text-align: center;
  background: #101419;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

button {
  cursor: pointer;
}

li {
  text-align: left;
  margin: .5rem 0;
}

/*# sourceMappingURL=index.a91255d8.css.map */
